import { gref } from "@/helpers/gref";
// показать/скрыть иконки настроек
export function showIcons(whatShow = "none") {
    switch (whatShow) {
        case "all":
        gref.value.get("var").showIconSettings = true;
        gref.value.get("var").showIconComponents = true;
        gref.value.get("var").showIconStyle = true;
        gref.value.get("var").showIconLock = true;
        break;
        case "settings":
        gref.value.get("var").showIconSettings = true;
        gref.value.get("var").showIconComponents = false;
        gref.value.get("var").showIconStyle = false;
        gref.value.get("var").showIconLock = false;
        break;
        case "components":
        gref.value.get("var").showIconSettings = false;
        gref.value.get("var").showIconComponents = true;
        gref.value.get("var").showIconStyle = false;
        gref.value.get("var").showIconLock = false;
        break;
        case "style":
        gref.value.get("var").showIconSettings = false;
        gref.value.get("var").showIconComponents = false;
        gref.value.get("var").showIconStyle = true;
        gref.value.get("var").showIconLock = false;
        break;

        default:
        gref.value.get("var").showIconSettings = false;
        gref.value.get("var").showIconComponents = false;
        gref.value.get("var").showIconStyle = false;
        gref.value.get("var").showIconLock = true;
        break;
    }
}