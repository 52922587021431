import { ref, shallowRef } from "@vue/runtime-core";
export const gref = ref(new Map([
    ["components", new Map()],
    ["layout", []],
    ["layout2", []],
    ["layout3", []],
    ["state", new Map()],
    ["settings", {
        hostname: "srv2.clusterfly.ru", //для lite версии, нельзя проставить сервер и порт
        port: "443", //для lite версии, нельзя проставить сервер и порт
        // hostname: "",
        // port: "",
        username: "",
        password: "",
        protocol: "wss",
        connectTimeout: "5000",
    }],
    ["var", {
        debug: false,
        showConfig: true,
        errorConnect: "Подключение ...",
        colorIconSettings: "#fff",

        zindexIconSettings: "1",
        colorIconComponents: "#fff",
        showIconSettings: false,

        zindexIconComponents: "1",
        componentsShow: false,
        componentShowEdit: false,
        showIconComponents: false,

        colorStyleSettings: "#fff",
        zindexStyleSettings: "1",
        showIconStyle: false,

        selectComponentId: "",

        currentVersion: "",
        versionUpdate: false,

        styleSettings: false,

        showError: false,

        alignItemFormComponent: "center",

        showIconLock: true,

        showButtonLayout1: false,
        showButtonLayout2: false,
        showButtonLayout3: false
    }],
]));

export const selectedComponent = shallowRef();