import { gref } from "@/helpers/gref";

// ТУТ ЛОГИЧЕСКАЯ ОШИБКА !!!! возвращаются всегда только сохраненные значения в локалсторейдж.
// если они как-то реактивно или еще как-то поменяются и не сохранятся - то борода
// также не будет реактивности даже если сохранятся

export default function settings(save = false) {
    if (save) {
        localStorage.setItem("settings", JSON.stringify(gref.value.get("settings")));
        return true;
    }

    if (localStorage.getItem("settings")) {
        const result = JSON.parse(localStorage.getItem("settings"));
        for (let key in result) {
            gref.value.get("settings")[key] = result[key];
        }
        // return true;
        return result;
    }

    return false;
}

settings()