<template>
  <div
    style="
      color: #000;
      position: absolute;
      width: 100%;
      z-index: 10;
      background-color: #fff;
    "
    v-show="showMessage"
  >
    <div class="container">
      <div class="col s12 center-align">{{ message }}</div>
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref } from "@vue/runtime-core";
const showMessage = ref(false);
const message = ref();

async function getMessage() {
  const url = `message?x=${new Date().getTime()}`;
  const response = await fetch(url);
  let currentMessage;
  if (response.ok) {
    currentMessage = await response.json();
  }

  if (localStorage.getItem("clusterflyDash")) {
    if (currentMessage?.visible == "on") {
      showMessage.value = true;
      message.value = currentMessage.msg;
    } else {
      showMessage.value = false;
    }
  }
}

async function getPersMessage() {
  const username = JSON.parse(localStorage.getItem("settings")).username;
  const url = `${username}_message?x=${new Date().getTime()}`;
  const response = await fetch(url);
  let currentMessage;
  if (response.ok) {
    currentMessage = await response.json();
  }
  if (localStorage.getItem("clusterflyDash")) {
    if (currentMessage?.visible == "on") {
      showMessage.value = true;
      message.value = currentMessage.msg;
    } else {
      showMessage.value = false;
    }
  }
}

onMounted(() => {
  setInterval(() => {
    // getMessage();
    // getPersMessage();
  }, 30000);
});
</script>
