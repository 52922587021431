import { gref } from "@/helpers/gref";
import { showIcons } from "./showIcons";
// показать/скрыть окно компонентов
export function selectComponent() {
    gref.value.get("var").componentsShow = !gref.value.get("var").componentsShow;

    if (gref.value.get("var").componentsShow) {
        showIcons("components");
        gref.value.get("var").showIconSettings = false;
        gref.value.get("var").colorIconComponents = "#000";
        gref.value.get("var").zindexIconComponents = "101";
    } else {
        showIcons("all");
        gref.value.get("var").showIconSettings = true;
        gref.value.get("var").colorIconComponents = "#fff";
        gref.value.get("var").zindexIconComponents = "1";
        
        gref.value.get("var").colorIconSettings = "#fff";
    }
}