import { gref } from "@/helpers/gref";
import { showIcons } from "./showIcons";
// показать/скрыть иконки настроек
export function style() {
    gref.value.get("var").styleSettings = !gref.value.get("var").styleSettings;
    if (gref.value.get("var").styleSettings) {
        showIcons("style");
        gref.value.get("var").colorStyleSettings = "#000";
        gref.value.get("var").zindexStyleSettings = "101";
    } else {
        showIcons("all");
        gref.value.get("var").colorStyleSettings = "#fff";
        gref.value.get("var").zindexStyleSettings = "1";
    }
}