<template style="border: 2px solid red">
  <div class="app">
    <styleComponent />
    <!-- message -->
    <message />
    <div class="cf__container_overlay" :style="cf__container_overlay">
      <div
        v-show="doNotTouchDivShow"
        ref="doNotTouchDiv"
        class="doNotTouch"
        style="border: 1px solid red; z-index: 200; position: absolute"
      ></div>
      <!-- sidebar > -->
      <div v-show="cf__left_sidebar" class="cf__left_sidebar">
        <div class="cf__top">
          <div
            v-if="gref.get('var').showIconSettings"
            class="material-symbols-outlined cf__nooutlined cf__mt_1r cf__zindex_1000"
            @click="config()"
            :style="[{ 'z-index': gref.get('var').zindexIconSettings }]"
          >
            <my_svg
              :icon="'settings'"
              :size="'37.5'"
              :color="gref.get('var').colorIconSettings"
            />
          </div>
          <div
            v-if="gref.get('var').showIconComponents"
            class="material-symbols-outlined cf__nooutlined cf__mt_1r"
            @click="selectComponent()"
            :style="[{ 'z-index': gref.get('var').zindexIconComponents }]"
          >
            <my_svg
              :icon="'library_add'"
              :size="'37.5'"
              :color="gref.get('var').colorIconComponents"
            />
          </div>
          <div
            v-if="gref.get('var').showIconStyle"
            class="material-symbols-outlined cf__nooutlined cf__mt_1r"
            @click="style()"
            :style="[{ 'z-index': gref.get('var').zindexStyleSettings }]"
          >
            <my_svg
              :icon="'design_services'"
              :size="'37.5'"
              :color="gref.get('var').colorStyleSettings"
            />
          </div>
        </div>
      </div>
      <!-- doNotTouch > -->
      <div class="cf__bottom cf__doNotTouch">
        <div
          class="material-symbols-outlined cf__nooutlined"
          @click="doNotTouch()"
          title="Только просмотр"
        >
          <my_svg_hover
            v-if="!doNotTouchDivShow"
            :icon="'do_not_touch'"
            :size="'37.5'"
            :color="'rgba(255, 255, 255, 0.2)'"
            :color_hover="'rgb(255, 255, 255)'"
          />
          <my_svg
            v-else
            :icon="'do_not_touch'"
            :size="'37.5'"
            :color="'rgb(255, 255, 255)'"
          />
        </div>
      </div>
      <!-- doNotTouch < -->
      <!-- layersButton > -->
      <div class="cf__layers_button">
        <div v-show="gref.get('var').showButtonLayout1">
          <div class="cf__num_layers" @click="layoutShow(1)">1</div>
        </div>
        <div v-show="gref.get('var').showButtonLayout2">
          <div class="cf__num_layers" @click="layoutShow(2)">2</div>
        </div>
        <div v-show="gref.get('var').showButtonLayout3">
          <div class="cf__num_layers" @click="layoutShow(3)">3</div>
        </div>
      </div>
      <!-- layersButton < -->
      <!-- sidebar < -->
      <div class="cf__lock" style="gap: 0.5rem" v-show="gref.get('var').showIconLock">
        <div class="cf__bottom">
          <div
            class="material-symbols-outlined cf__nooutlined"
            @click="lockUnlock()"
            :title="gref.get('var').currentVersion"
          >
            <my_svg_hover
              v-if="lockUnlockIcon == 'lock'"
              :icon="'lock'"
              :size="'37.5'"
              :color="'rgba(255, 255, 255, 0.2)'"
              :color_hover="'rgb(255, 255, 255)'"
            />
            <my_svg_hover
              v-if="lockUnlockIcon == 'lock_open'"
              :icon="'lock_open'"
              :size="'37.5'"
              :color="'rgba(255, 255, 255, 0.2)'"
              :color_hover="'rgb(255, 255, 255)'"
            />
          </div>
        </div>
      </div>
      <!-- error -->
      <error />
      <!-- components -->
      <showComponents />
      <!-- settings -->
      <settings />
      <!-- version -->
      <version />
      <!-- layout 3 > -->
      <div
        :style="{
          'z-index': zindexLayout3,
          'background-image': `url(${backgroundImage})`,
        }"
        style="
          position: absolute;
          width: 100%;
          min-height: 100%;
          top: 0;
          left: 0;
          background-color: #9e9c9c;
        "
        data-name="layout number 3"
      >
        <div class="cf__grid">
          <grid-layout
            v-model:layout.sync="layout3"
            :col-num="12"
            :row-height="30"
            :is-draggable="draggable"
            :is-resizable="resizable"
            :vertical-compact="true"
            :use-css-transforms="false"
            :margin="[10, 10]"
            :is-bounded="true"
          >
            <!-- <grid-layout
        :prevent-collision="true"
        :restoreOnDrag="true"
        :responsive="true"
      > -->
            <grid-item
              v-for="(item, k) in layout3"
              v-bind:key="k"
              :static="item.static"
              :x="item.x"
              :y="item.y"
              :w="item.w"
              :h="item.h"
              :i="item.i"
              :minH="item.minH"
              :minW="item.minW"
              :isDraggable="item.isDraggable"
              :isResizable="item.isResizable"
              :isBounded="item.isBounded"
              @moved="itemMovedEvent"
              @resized="itemResizedEvent"
              class="cf__center cf__component"
              :style="gridItemCss"
            >
              <div
                v-if="!showComponent"
                class="material-symbols-nocur cf__fs_2-5r cf__fw_600"
              >
                <!-- open_with -->
                <my_svg :icon="'open_with'" :size="'30'" :color="'rgb(255, 255, 255)'" />
              </div>
              <div v-if="!showComponent">{{ item.params.title }}</div>
              <component
                v-if="item.status && showComponent"
                :is="loadComponent(item.name, item.params.id)"
                :id="item.params.id"
                :show="true"
              ></component>
              <!-- </div> -->
              <div>
                <i
                  v-show="editItem"
                  class="material-symbols-outlined cf__nooutlined edit"
                  @click="changeItem(item.name, item.i)"
                >
                  <!-- edit -->
                  <my_svg :icon="'edit'" :size="'30'" :color="'rgb(255, 255, 255)'" />
                </i>
                <i
                  v-show="deleteItem"
                  class="material-symbols-outlined cf__nooutlined remove"
                  @click="removeItem(item.i)"
                >
                  <!-- close -->
                  <my_svg :icon="'close'" :size="'30'" :color="'rgb(255, 255, 255)'" />
                </i>
              </div>
            </grid-item>
          </grid-layout>
        </div>
      </div>
      <!-- layout 3 < -->
      <!-- layout 2 > -->
      <div
        :style="{
          'z-index': zindexLayout2,
          'background-image': `url(${backgroundImage})`,
        }"
        style="
          position: absolute;
          width: 100%;
          min-height: 100%;
          top: 0;
          left: 0;
          background-color: #9e9c9c;
        "
        data-name="layout number 2"
      >
        <div class="cf__grid">
          <grid-layout
            v-model:layout.sync="layout2"
            :col-num="12"
            :row-height="30"
            :is-draggable="draggable"
            :is-resizable="resizable"
            :vertical-compact="true"
            :use-css-transforms="false"
            :margin="[10, 10]"
            :is-bounded="true"
          >
            <!-- <grid-layout
        :prevent-collision="true"
        :restoreOnDrag="true"
        :responsive="true"
      > -->
            <grid-item
              v-for="(item, k) in layout2"
              v-bind:key="k"
              :static="item.static"
              :x="item.x"
              :y="item.y"
              :w="item.w"
              :h="item.h"
              :i="item.i"
              :minH="item.minH"
              :minW="item.minW"
              :isDraggable="item.isDraggable"
              :isResizable="item.isResizable"
              :isBounded="item.isBounded"
              @moved="itemMovedEvent"
              @resized="itemResizedEvent"
              class="cf__center cf__component"
              :style="gridItemCss"
            >
              <div
                v-if="!showComponent"
                class="material-symbols-nocur cf__fs_2-5r cf__fw_600"
              >
                <!-- open_with -->
                <my_svg :icon="'open_with'" :size="'30'" :color="'rgb(255, 255, 255)'" />
              </div>
              <div v-if="!showComponent">{{ item.params.title }}</div>
              <component
                v-if="item.status && showComponent"
                :is="loadComponent(item.name, item.params.id)"
                :id="item.params.id"
                :show="true"
              ></component>
              <!-- </div> -->
              <div>
                <i
                  v-show="editItem"
                  class="material-symbols-outlined cf__nooutlined edit"
                  @click="changeItem(item.name, item.i)"
                >
                  <!-- edit -->
                  <my_svg :icon="'edit'" :size="'30'" :color="'rgb(255, 255, 255)'" />
                </i>
                <i
                  v-show="deleteItem"
                  class="material-symbols-outlined cf__nooutlined remove"
                  @click="removeItem(item.i)"
                >
                  <!-- close -->
                  <my_svg :icon="'close'" :size="'30'" :color="'rgb(255, 255, 255)'" />
                </i>
              </div>
            </grid-item>
          </grid-layout>
        </div>
      </div>
      <!-- layout 2 < -->
      <!-- layout 1 > -->
      <div
        class="cf__grid"
        :style="{
          'z-index': showLayout1,
          'background-image': `url(${backgroundImage})`,
          'background-color': backgroundMainColor,
        }"
        style="position: absolute; width: 100%; min-height: 100%"
        data-name="layout number 1"
      >
        <grid-layout
          v-model:layout.sync="layout1"
          :col-num="12"
          :row-height="30"
          :is-draggable="draggable"
          :is-resizable="resizable"
          :vertical-compact="true"
          :use-css-transforms="false"
          :margin="[10, 10]"
          :is-bounded="true"
        >
          <!-- <grid-layout
        :prevent-collision="true"
        :restoreOnDrag="true"
        :responsive="true"
      > -->
          <grid-item
            v-for="(item, k) in layout1"
            v-bind:key="k"
            :static="item.static"
            :x="item.x"
            :y="item.y"
            :w="item.w"
            :h="item.h"
            :i="item.i"
            :minH="item.minH"
            :minW="item.minW"
            :isDraggable="item.isDraggable"
            :isResizable="item.isResizable"
            :isBounded="item.isBounded"
            @moved="itemMovedEvent"
            @resized="itemResizedEvent"
            class="cf__center cf__component"
            :style="gridItemCss"
          >
            <div
              v-if="!showComponent"
              class="material-symbols-nocur cf__fs_2-5r cf__fw_600"
            >
              <!-- open_with -->
              <my_svg :icon="'open_with'" :size="'30'" :color="'rgb(255, 255, 255)'" />
            </div>
            <div v-if="!showComponent">{{ item.params.title }}</div>
            <component
              v-if="item.status && showComponent"
              :is="loadComponent(item.name, item.params.id)"
              :id="item.params.id"
              :show="true"
            ></component>
            <!-- </div> -->
            <div>
              <i
                v-show="editItem"
                class="material-symbols-outlined cf__nooutlined edit"
                @click="changeItem(item.name, item.i)"
              >
                <!-- edit -->
                <my_svg :icon="'edit'" :size="'30'" :color="'rgb(255, 255, 255)'" />
              </i>
              <i
                v-show="deleteItem"
                class="material-symbols-outlined cf__nooutlined remove"
                @click="removeItem(item.i)"
              >
                <!-- close -->
                <my_svg :icon="'close'" :size="'30'" :color="'rgb(255, 255, 255)'" />
              </i>
            </div>
          </grid-item>
        </grid-layout>
      </div>
      <!-- layout 1 < -->
    </div>
  </div>
</template>

<script setup>
import "@/assets/materialize.min.css";
import "@/assets/app.scss";
import { onMounted, ref, shallowRef } from "@vue/runtime-core";
import { mqtt } from "@/helpers/mqtt";
import { GridLayout, GridItem } from "vue3-grid-layout";
import availableComponents from "@/helpers/available-components";
import { gref, selectedComponent } from "@/helpers/gref";
import { saveToLocalStore } from "@/helpers/saveToLocalStore";
import error from "@/view/error.vue";
import modal from "@/view/modal.vue";
import settings from "@/view/settings.vue";
import version from "@/view/version.vue";
import showComponents from "@/components/components.vue";
import resetSetting from "@/helpers/resetSetting";
import { removeItem } from "@/helpers/removeItem";
import { selectComponent } from "@/helpers/selectComponent";
import { showIcons } from "@/helpers/showIcons";
import { style } from "@/helpers/style";
import { versionUpdate } from "@/helpers/versionUpdate";
import styleComponent from "@/view/style.vue";
import message from "@/view/message.vue";
import { loadFromStore } from "@/helpers/loadFromStore";
import my_svg from "@/helpers/svg.vue";
import my_svg_hover from "@/helpers/svg_hover.vue";

const debug = gref.value.get("var").debug;
const gridItemCss = ref([]); // пока неиспользуется
const layout1 = gref.value.get("layout");
// костыль с несколькими слоями (табами)
const layout2 = gref.value.get("layout2");
const layout3 = gref.value.get("layout3");
const showLayout1 = ref(4);
const zindexLayout2 = ref(0);
const zindexLayout3 = ref(0);
const layout1Bg = ref("");
const layout2Bg = ref("");
const layout3Bg = ref("");
const doNotTouchDiv = ref("");
const doNotTouchDivShow = ref(false);
// async function icons(url) {
//   url = "https://localhost:8080" + url;
//   fetch(url)
//     .then((response) => response.text())
//     .then((svg) => {
//       return svg;
//     });
// }

function layoutShow(layoutNumber) {
  showLayout1.value = 0;
  zindexLayout2.value = 0;
  zindexLayout3.value = 0;
  switch (layoutNumber) {
    case 1:
      showLayout1.value = 4;
      break;
    case 2:
      zindexLayout2.value = 4;
      break;
    case 3:
      zindexLayout3.value = 4;
      break;
    default:
      showLayout1.value = 4;
      break;
  }
}

const draggable = ref();
const resizable = ref();
const deleteItem = ref(true);
const editItem = ref(true);
const lockUnlockIcon = ref("lock_open");
const layoutUpdated = ref(false);
const readyLayout = ref(0);
const showComponent = ref(false);
const showConfig = gref.value.get("var").showConfig;
const backgroundImage = localStorage.getItem("background") || "img/bg_blank.jpg";
const backgroundMainColor =
  JSON.parse(localStorage.getItem("style"))?.styleMainBackgroundColor || "rgba(0,0,0,1)";
const cf__container_overlay = ref({ display: "block" });
const cf__left_sidebar = ref(false);
const arrr = ref([]);

// icons
// gref.get('var').showIconSettings
// gref.get('var').showIconComponents
// gref.get('var').showIconVisualization
// const iconLock = ref(true);

onMounted(async () => {
  // if (backgroundImage) {
  //   document.body.style.background = `url(${backgroundImage})`;
  // } else {
  //   document.body.style.background = `background-color:#fefefe`;
  // }

  gref.value.get("var").currentVersion = await versionUpdate();

  const dashLock = localStorage.getItem("clusterflyDashLock");
  if (dashLock == "true") {
    resizable.value = draggable.value = true;
  } else {
    resizable.value = draggable.value = false;
  }
  lockUnlock();
  gref.value.get("var").showConfig = false;
});

function loadComponent(item, id) {
  return availableComponents(item);
}

const itemMovedEvent = (i, newX, newY) => {
  if (debug) console.log("itemMovedEvent");
  gref.value.get("components").get(i).x = newX;
  gref.value.get("components").get(i).y = newY;
  saveToLocalStore();
};

const itemResizedEvent = (i, newH, newW, newHPx, newWPx) => {
  if (debug) console.log("itemResizedEvent");
  gref.value.get("components").get(i).h = newH;
  gref.value.get("components").get(i).w = newW;
  saveToLocalStore();
};

function loadFromLocalStore() {
  // const item = localStorage.getItem("clusterflyDash");
  let item = localStorage.getItem("clusterflyDash");
  if (item) {
    // item = item.replaceAll('\\"', '\\\\"');
    // item = item.replaceAll("\\'", "\\\\'");
    const locStorage = JSON.parse(item);
    for (const key of Object.keys(locStorage)) {
      gref.value.get("components").set(key, locStorage[key]);
    }
  }
}
loadFromLocalStore();

// выгрузка из стора
loadFromStore();

// изменение комоонентов
const changeItem = (item, id = false) => {
  // костыль, чтобы изменить позиционирование формы для компонента toggle_plus
  if (item == "toggle_plus") gref.value.get("var").alignItemFormComponent = "start";
  else gref.value.get("var").alignItemFormComponent = "center";

  gref.value.get("var").componentsShow = !gref.value.get("var").componentsShow;
  gref.value.get("var").componentShowEdit = !gref.value.get("var").componentShowEdit;

  if (id) gref.value.get("var").selectedComponentId = id;
  selectedComponent.value = availableComponents(item);

  //08-10-2023
  // if (gref.value.get("var").componentsShow) showIcons("none");
};

// только просмотр
function doNotTouch() {
  doNotTouchDiv.value.style.width = `${document.documentElement.clientWidth}px`;
  doNotTouchDiv.value.style.height = `${document.documentElement.clientHeight - 1}px`;
  doNotTouchDivShow.value = !doNotTouchDivShow.value;
}
// блокировка компонентов
function lockUnlock() {
  if (debug) console.log("lockUnlock");
  gref.value.get("var").selectedComponentId = "";

  if (resizable.value && draggable.value) {
    // 08-10-2023
    showIcons("none");
    // закрыт
    resizable.value = draggable.value = deleteItem.value = editItem.value = false;
    lockUnlockIcon.value = "lock";
    localStorage.setItem("clusterflyDashLock", "true");
    showComponent.value = true;

    cf__container_overlay.value = { display: "block" };
    cf__left_sidebar.value = false;
  } else {
    // 08-10-2023
    showIcons("all");
    // открыт
    showComponent.value = false;
    resizable.value = draggable.value = deleteItem.value = editItem.value = true;
    lockUnlockIcon.value = "lock_open";
    localStorage.setItem("clusterflyDashLock", "false");

    cf__container_overlay.value = { display: "grid" };
    cf__left_sidebar.value = true;
  }
}

// показать/скрыть окно настроек
function config() {
  gref.value.get("var").showConfig = !gref.value.get("var").showConfig;
  if (gref.value.get("var").showConfig) {
    showIcons("settings");
    gref.value.get("var").colorIconSettings = "#000";
    gref.value.get("var").zindexIconSettings = "101";
  } else {
    showIcons("all");
    gref.value.get("var").colorIconSettings = "#fff";
    gref.value.get("var").zindexIconSettings = "1";
  }
}
</script>
