<template>
  <div
    style="
      color: #000;
      position: fixed;
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      z-index: 100;
      overflow-x: hidden;
      background-color: #fff;
    "
    v-show="gref.get('var').showConfig"
  >
    <div class="container">
      <div class="row">
        <div class="col s12 m12" style="display: flex; justify-content: space-between">
          <h5 style="font-weight: 500">Настройки</h5>
          <i
            v-if="false"
            class="material-symbols-outlined"
            @click="gref.set('modalShow', false)"
            style="font-size: 2rem; font-weight: 500"
          >
            <!-- close -->
            <my_svg :icon="'close'" :size="'37.5'" :color="'rgb(255, 255, 255)'" />
          </i>
        </div>
        <form class="row" @submit.prevent="saveSettings()">
          <div class="col s12 m5">
            <div class="row">
              <div v-show="false" class="cf__input_field col m6 s12">
                <input
                  type="text"
                  placeholder="srv2.clusterfly.ru"
                  v-model="gref.get('settings').hostname"
                />
                <span class="helper-text">адрес сервера</span>
              </div>
              <div v-show="false" class="cf__input_field col m6 s12">
                <input
                  type="text"
                  placeholder="443"
                  v-model="gref.get('settings').port"
                />
                <span class="helper-text">порт</span>
              </div>
              <div class="cf__input_field col m6 s12">
                <input
                  type="text"
                  autocomplete="username"
                  id="username"
                  placeholder="логин user_"
                  v-model="gref.get('settings').username"
                  required
                />
                <span class="helper-text">логин</span>
              </div>
              <div class="cf__input_field col m6 s12">
                <input
                  type="password"
                  autocomplete="current-password"
                  id="current-password"
                  placeholder="пароль pass_"
                  v-model="gref.get('settings').password"
                  required
                />
                <span class="helper-text">пароль</span>
              </div>
              <div class="cf__input_field col s12" style="text-align: left">
                <label
                  style="font-size: inherit; color: inherit; cursor: pointer"
                  for="cf__upload_bg"
                  >Выбрать фон</label
                >
                <label class="cf_selected_filename">{{ seletedFilename }}</label>
                <input
                  type="file"
                  label
                  name="uploadBg"
                  id="cf__upload_bg"
                  @change="backgroundUploader()"
                />
              </div>
              <div class="cf__input_field col m4 s12">
                <button
                  class="btn waves-effect waves-light"
                  style="overflow: visible"
                  type="submit"
                >
                  Сохранить
                </button>
              </div>
              <div class="cf__input_field col m8 s12">
                <span class="helper-text"
                  >Настройки хранятся локально в браузере и не передаются.
                  <input
                    type="file"
                    label
                    name="importSettings"
                    id="cf__import_settings"
                    @change="importSettings()"
                  />
                  <label style="color: #000; cursor: pointer" for="cf__import_settings"
                    >Загрузить</label
                  >
                  /
                  <label style="color: #000; cursor: pointer" @click="exportSettings()"
                    >Выгрузить</label
                  >
                  настройки.</span
                >
              </div>
              <div class="col s12 helper-text cf__mt_1r">
                Вы можете
                <label style="color: #000; cursor: pointer" @click="uploadSettingsToSrv()"
                  >загрузить</label
                >
                и
                <label
                  style="color: #000; cursor: pointer"
                  @click="loadSettingsFromServer()"
                  >выгрузить</label
                >
                настройки на/с сервер(а), если необходимо перенести дашборд на другое
                устройство.
                <div v-show="showSettingsDownload != ''">
                  <span v-html="showSettingsDownload"></span>
                </div>
              </div>
              <div class="col s12 helper-text cf__mt_1r">
                Дашборд работает только с сервером srv2.clustefly.ru по порту 443 tls.
              </div>
              <div class="col s12 helper-text cf__mt_1r">
                Использование сервиса возможно только в некоммерческих целях. Доступ к
                сервису предоставляется на безвозмездной основе и по принципу - "как
                есть".
              </div>
              <div class="col s12 helper-text cf__mt_1r">
                Для ознакомления с функционалом дашборда, рекомендуем посмотреть
                <label style="color: #000; cursor: pointer" @click="instruction()"
                  >инструкцию</label
                >.
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div
      v-show="instructionContainer"
      id="instruction_container"
      style="
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgb(255, 255, 255);
        z-index: 1;
        display: flex;
        justify-content: center;
      "
    >
      <div id="instruction">
        <img
          src="/img/dashboard-instruction.webp"
          loading="lazy"
          alt="Инструкция по дашборду"
          style="width: 70vw"
          @click="instruction()"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted } from "@vue/runtime-core";
import { gref } from "@/helpers/gref";
import settings from "@/helpers/settings";
import { selectComponent } from "@/helpers/selectComponent";
import { exportSettings } from "@/helpers/exportSettings";
import my_svg from "@/helpers/svg.vue";
import CryptoJS from "crypto-js";
import { cryptFileName } from "@/helpers/cryptFileName";

const seletedFilename = ref("");
const showSettingsDownload = ref("");
const showSettingsUpload = ref(false);
const urlSettings = ref("");
const resultUploadSettingsToSrv = ref("");
const instructionContainer = ref(false);
// восстановить бекап
function importSettings() {
  const file = document.querySelector("input[name=importSettings]")["files"][0];
  const reader = new FileReader();
  reader.onload = function () {
    localStorage.setItem("clusterflyDash", reader.result);
  };
  reader.readAsText(file);
  window.document.location.reload();
}

function backgroundUploader() {
  const file = document.querySelector("input[name=uploadBg]")["files"][0];
  seletedFilename.value = file.name;
  const reader = new FileReader();
  reader.onload = function () {
    console.log("background");
    localStorage.setItem("background", reader.result);
  };
  reader.readAsDataURL(file);
  window.document.location.reload();
}

function saveSettings() {
  settings(true);
  settings();
  window.document.location.reload();
  // gref.value.get("var").showConfig = false;
}

// загрузка на сервер
function uploadSettingsToSrv() {
  if (
    gref.value.get("settings").username == "" ||
    gref.value.get("settings").password == ""
  ) {
    showSettingsDownload.value =
      '<span style="color: #cf4e0e">Для сохранения настроек на сервере, введите логин/пароль.</span>';
    return false;
  }

  const solt = cryptFileName(
    gref.value.get("settings").username + gref.value.get("settings").password,
    gref.value.get("settings").username + gref.value.get("settings").password
  );

  const encrypted = CryptoJS.AES.encrypt(
    localStorage.getItem("clusterflyDash").toString(),
    solt
  ).toString();

  (async () => {
    const rawResponse = await fetch("https://dash.clusterfly.ru/users_config/", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({ solt: solt, data: encrypted }),
    });
    const content = await rawResponse.json();
    if (content.status == "ok")
      showSettingsDownload.value =
        '<span style="color: #0b7e3b">Настройки успешно выгружены на сервер.</span>';
    else
      showSettingsDownload.value =
        '<span style="color: #cf4e0e">Ошибка загрузки настроек, попробуйте еще раз.</span>';
  })();
}
// выгрузка с сервера в дашборд
function loadSettingsFromServer() {
  if (
    gref.value.get("settings").username == "" ||
    gref.value.get("settings").password == ""
  ) {
    showSettingsDownload.value =
      '<span style="color: #cf4e0e">Для выгрузки настроек с сервера, введите логин/пароль.</span>';
    return false;
  }
  const solt = cryptFileName(
    gref.value.get("settings").username + gref.value.get("settings").password,
    gref.value.get("settings").username + gref.value.get("settings").password
  );

  urlSettings.value = `https://dash.clusterfly.ru/users_config/${solt}.txt`;

  if (urlSettings.value == "") {
    console.log("Пустой url настроек");
    return false;
  }

  fetch(urlSettings.value)
    .then((resp) => resp.text())
    .then(function (data) {
      // Decrypt
      const bytes = CryptoJS.AES.decrypt(data, solt);
      const originalText = bytes.toString(CryptoJS.enc.Utf8);
      localStorage.setItem("clusterflyDash", originalText);
      showSettingsDownload.value =
        '<span style="color: #0b7e3b">Настройки успешно выгружены, нажмите кнопку "Сохранить"</span>';
    })
    .catch(function (error) {
      console.log(error);
      resultUploadSettingsToSrv.value =
        "<span style='color:#ff3d00'>Ошибка загрузки настроек, попробуйте еще раз!</span>";
      return false;
    });
}

// инструкция
function instruction() {
  instructionContainer.value = !instructionContainer.value;
  gref.value.get("var").showIconSettings = !gref.value.get("var").showIconSettings;
}
</script>
