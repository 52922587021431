<template>
  <svg
    v-if="result != ''"
    ref="my_svg"
    xmlns="http://www.w3.org/2000/svg"
    :fill="props.color"
    :width="props.size"
    :height="props.size"
    viewBox="0 0 24 24"
    v-html="result"
    style="display: flex"
    @mouseover="my_svg.style.fill = props.color_hover"
    @mouseleave="my_svg.style.fill = props.color"
  ></svg>
</template>

<script setup>
import { onMounted, ref } from "@vue/runtime-core";
const props = defineProps({
  icon: String,
  size: String,
  color: String,
  color_hover: String,
});
const result = ref("");
const my_svg = ref();

onMounted(async () => {
  const url = `${location.toString()}img/svg_200/${props.icon}_wght200_24px.svg`;
  if (props.icon != "undefined") {
    await fetch(url)
      .then((response) => response.text())
      .then((data) => (result.value = data.replace(/^<svg.*?>(.*)<\/svg>/gm, "$1")));
  } else {
    console.log("ошибка загрузки иконки");
  }
});
</script>
