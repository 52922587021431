import { gref } from "./gref";

export async function versionUpdate() {

    const url = `version?x=${new Date().getTime()}`;
    const response = await fetch(url);
    let currentVersion;
    if (response.ok) {
        currentVersion = await response.text();
    } else {
        console.log("Ошибка загрузки version: " + response.status);
    }

    const storageVersion = localStorage.getItem("version");
    if (currentVersion != storageVersion) {
        if (localStorage.getItem('clusterflyDash'))
            gref.value.get("var").versionUpdate = true;
        else window.document.location.reload();

        localStorage.setItem("version", currentVersion);
    }

    return currentVersion;
}