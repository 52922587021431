<template>
  <div class="cf__modal" v-show="gref.get('var').versionUpdate">
    <div class="cf__version_wrap row">
      <div class="col s12" style="text-align: center">
        <h5>Версия приложения обновлена</h5>
        <p>Сбросьте кэш браузера (CTRL+SHIFT+R | CTRL+SHIFT+F5)</p>
        <p>
          Предварительно
          <a href="#" style="text-decoration: underline" @click="exportSettings()"
            >сделайте</a
          >
          резервную копию настроек.
        </p>
        <p v-if="oldversion">
          Ваша версия: {{ oldversion }} &raquo; текущая версия:
          {{ gref.get("var").currentVersion }}
        </p>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, computed } from "@vue/runtime-core";
import { gref } from "@/helpers/gref";
import { exportSettings } from "@/helpers/exportSettings";
const oldversion = computed(() => {
  return localStorage.getItem("version");
});
</script>
