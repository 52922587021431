import { gref } from "@/helpers/gref";
export function loadFromStore() {
    // console.log("loadFromStore");
    for (const [key, val] of gref.value.get("components")) {
        // orig 07-10-2023
        // gref.value.get("layout").push(gref.value.get("components").get(key));
        // костыль с несколькими слоями
        // if (
        //   typeof gref.value.get("components").get(key).layout == "undefined" ||
        //   gref.value.get("components").get(key).layout == "1"
        // ) {
        //   gref.value.get("layout").push(gref.value.get("components").get(key));
        // }
        switch (gref.value.get("components").get(key).params.layout) {
        case "2":
            // console.log("case2");
            gref.value.get("layout2").push(gref.value.get("components").get(key));
            gref.value.get("var").showButtonLayout1 = true;
            gref.value.get("var").showButtonLayout2 = true;
            break;
        case "3":
            // console.log("case3");
            gref.value.get("layout3").push(gref.value.get("components").get(key));
            gref.value.get("var").showButtonLayout1 = true;
            gref.value.get("var").showButtonLayout3 = true;
            break;
        default:
            // console.log("default");
            gref.value.get("layout").push(gref.value.get("components").get(key));
            // gref.value.get("var").showButtonLayout1 = false;
            // gref.value.get("var").showButtonLayout2 = false;
            // gref.value.get("var").showButtonLayout3 = false;
            break;
        }
    }
    }