import * as MQTT from "async-mqtt";
import { ref, computed, watch } from "@vue/runtime-core";
import { gref } from "@/helpers/gref";

const debug = gref.value.get("var").debug;
const showError = error => {
    switch (error.toString()) {
        case 'Error':
            if (gref.value.get('settings').server == '')
                gref.value.get("var").errorConnect = 'Отсутствует адрес сервера, необходимо настроить подключение';
            else gref.value.get("var").errorConnect = 'Неопределенная ошибка, проверьте настройки';
            break;
        case 'Error: Connection refused: Bad username or password':
        case 'Error: Connection refused: Not authorized':
            gref.value.get("var").errorConnect = 'Неверные логин/пароль, ошибка авторизации<br/>Если Вы используете дашборд в первый раз, попробуйте ознакомиться с демо';
            break;
        case 'offline':
            gref.value.get("var").errorConnect = 'Отсутствует подключение к серверу';
            if (!gref.value.get('var').showError) gref.value.get('var').showError = true;
            break;
        default:
            gref.value.get("var").errorConnect = error;
            // gref.value.get("var").errorConnect = 'Неопознанная ошибка, проверьте настройки подключения';
            break;
    }
}

// Настройки. Функция нужна для обхода проблемы, что мкутт пишет свои данные в тот же объект и начинается циклическая реактивность
const settings = gref.value.get('settings')
function getSettings(settings_) {
    const settings = {
        // hostname: settings_.hostname.toString(),
        hostname: 'srv2.clusterfly.ru', //для lite версии, нельзя проставить сервер и порт
        // port: settings_.port.toString(),
        port: '443',//для lite версии, нельзя проставить сервер и порт
        username: settings_.username.toString(),
        password: settings_.password.toString(),
        protocol: settings_.protocol.toString(),
        connectTimeout: settings_.connectTimeout.toString(),
    }
    return settings
}

//Статус
export const mqtt = {
    status: ref(null),
    error: ref(null),
    lastAction: ref(null),
    lastConnection: ref(null),
    log: ref([]),
    lastLog: computed(() => {
        const log = {
            date: mqtt.lastAction.value > mqtt.lastConnection.value ? mqtt.lastAction.value : mqtt.lastConnection.value,
            status: mqtt.status.value,
            error: mqtt.error.value
        }
        mqtt.log.value.unshift(log)
        while (mqtt.log.value.length > 10000) mqtt.log.value.pop()
        return log;
    }),
}

// Клиент.
export const client = ref(null)

function connect(settings_, iteration) {
    iteration++
    if (debug) console.log("Итерация подключения:", iteration)
    if (client.value) client.value.end()
    mqtt.status.value = "init"
    mqtt.error.value = null
    mqtt.lastAction.value = null
    const settings = getSettings(settings_)

    const client_ = MQTT.connect(settings)

    client_.on("message", function (topic, payload) {
        // if (debug) console.log('connect3');
        const _topic = letTopic(topic)

        if(_topic?.type == 'string') payload = payload.toString()
        _topic.payload.value = payload
        _topic.lastUpdate.value = Date.now()
        // if (debug) console.log("message", topic, payload);
    })

    client_.on('connect', (connack) => {
        // if (debug) console.log('connect4');
        mqtt.status.value = 'online'
        mqtt.error.value = null
        mqtt.lastConnection.value = Date.now()

        gref.value.get('var').showError = false;
        if (debug) console.log('Клиент ПОДКЛЮЧИЛСЯ:', connack);
    })
    client_.on('error', (error) => {
        // if (debug) console.log('connect5');
        mqtt.status.value = 'error'
        mqtt.error.value = error
        showError(error);
        if (debug) console.log('Клиент вернул ошибку:', error)
    })
    client_.on('offline', () => {
        // if (debug) console.log('connect6');
        mqtt.status.value = 'offline'
        showError('offline');

        if (debug) console.log('Клиент ушел в offline');
    })
    client_.on('packetreceive', (packet) => {
        // if (debug) console.log('connect7');
        mqtt.lastAction.value = Date.now();
    })

    if (debug) console.log("Клиент создан", client_)
    client.value = client_

    //переподписываемся на топики
    for (let topic of topics.keys()) {
        subscribe(topic)
        if (debug) console.log("переподписываемся на топик:", topic)
    }
    return iteration
}

function connectionError(error) {
    if (debug) console.log("Произошла ошибка при подключении:", error)
    showError("Произошла ошибка при подключении: " + error.toString());
}

//Очередь. Чтобы реактивное изменение не конкурировало за создание клиентов
let queue = Promise.resolve(0).then(i => connect(settings, i), connectionError)

// Следим за настройками и пересоздаем клиент при изменении
watch(settings, (now, prev) => {
    queue = queue.then(i => connect(now, i), connectionError)
})

// тут храним данные для топиков
const topics = new Map()

function letTopic(topic, type = 'raw') {
    if (!topics.has(topic)) {
        topics.set(topic, {
            payload: ref(null),
            lastUpdate: ref(null),
            type
        })
        subscribe(topic)
        if (debug) console.log('кто-то подписался на топик:', topic)
    }
    return topics.get(topic)
}

export function getTopic(topic, type='string') {
    return letTopic(topic, type)

}

//Публикация
export function mqttPublish(topic, payload, retain = false) {
    publish(topic, payload.toString(), retain) //тут наверное можно сделать ретурн, если надо асинхронно получать статус
}


//Подписка
async function subscribe(topic) {
    if (await client.value) {
        try {
            client.value.subscribe(topic)
        } catch (error) {
            // if (debug) console.log("БЯДА-ОГОРЧЕНИЕ", error);
        }

    }
}

//Публикация
async function publish(topic, payload, retain) {
    // if (debug) console.log(client)
    if (!client.value) {
        if (debug) console.log('Публикации не будет - клиента НЕТ')
        return
    }
    if (mqtt.status.value != 'online') {
        if (debug) console.log('Публикации не будет - клиент не онлайн')
        return
    }

    client.value.publish(topic, payload.toString(), { retain: retain });
    // if (debug) console.log("publish", topic, payload, retain)
}