import { gref } from "@/helpers/gref";
import { saveToLocalStore } from "@/helpers/saveToLocalStore";
import { loadFromStore } from "@/helpers/loadFromStore";

export function removeItem(id) {
  // 08-10-2023
  let layout_id = "layout";

  const index = gref.value
    .get(layout_id)
    .map((item) => item.i)
    .indexOf(id);
  // const index = gref.value
  //   .get("layout")
  //   .map((item) => item.i)
  //   .indexOf(id);

  // 08-10-2023
  const selectedComponent = gref.value.get("components").get(id);
  if (typeof selectedComponent.params?.layout == undefined || selectedComponent.params?.layout == "1" )
    layout_id = `layout`
  else 
    layout_id = `layout${selectedComponent.params.layout}`;
  // console.log(layout_id)

  gref.value.get("components").delete(id);
  if (selectedComponent.params?.layout != undefined) {
    console.log('beee')
    gref.value.get(layout_id).splice(index, 1);
  }
  else
  gref.value.get("layout").splice(index, 1);

  // gref.value.get("layout").splice(index, 1);
  // удаление данных компонента (если это компонент типа "Лог" который хранит состояние)
  if (localStorage.getItem(id)) localStorage.removeItem(id);
  // обновляем общие настройки всех компонентов
  saveToLocalStore();
  console.log('remote item - ok');
  loadFromStore();
  window.document.location.reload();
}