import { defineAsyncComponent, ref } from "@vue/runtime-core";
const components = {
    toggle: () => import("@/components/toggle/index.vue"),
    clock: () => import("@/components/clock/index.vue"),
    weather: () => import("@/components/weather/index.vue"),
    iframe: () => import("@/components/iframe/index.vue"),
    log: () => import("@/components/log/index.vue"),
    slider1: () => import("@/components/slider_1/index.vue"),
    text: () => import("@/components/text/index.vue"),
    therm: () => import("@/components/therm/index.vue"),
    speed: () => import("@/components/speed/index.vue"),
    barchart: () => import("@/components/barchart/index.vue"),
    linechart: () => import("@/components/linechart/index.vue"),
    image: () => import("@/components/image/index.vue"),
    therm_plus: () => import("@/components/therm_plus/index.vue"),
    toggle_plus: () => import("@/components/toggle_plus/index.vue"),
    icon: () => import("@/components/icon/index.vue"),
};
export default function (component) {
    return defineAsyncComponent(
        components[component]
    );
}