<template>
  <div
    style="
      color: #000;
      position: fixed;
      display: flex;
      width: 100%;
      height: 100%;
      z-index: 100;
      overflow-x: hidden;
      background-color: #fff;
    "
    :style="[{ 'align-items': gref.get('var').alignItemFormComponent }]"
    v-show="gref.get('var').componentsShow"
  >
    <div class="container">
      <div class="row" v-show="!gref.get('var').componentShowEdit">
        <div class="col s12" style="display: flex; justify-content: space-between">
          <h5 style="font-weight: 500">Базовые компоненты</h5>
        </div>
        <div class="col m6 s12">
          <div class="cf__add_comps">
            <div class="cf__flex" style="flex-wrap: wrap">
              <div class="cf__add_comp cf__noselect" @click="showEditComp('toggle')">
                <i class="icon cf__icon">
                  <my_svg
                    :icon="'smart_button'"
                    :size="'37.5'"
                    :color="'rgb(145, 145, 145)'"
                  />
                </i>
                <div class="desc">кнопка</div>
              </div>
              <div class="cf__add_comp cf__noselect" @click="showEditComp('clock')">
                <i class="icon cf__icon">
                  <my_svg
                    :icon="'calendar_today'"
                    :size="'37.5'"
                    :color="'rgb(145, 145, 145)'"
                  />
                </i>
                <div class="desc">дата</div>
              </div>
              <div class="cf__add_comp cf__noselect" @click="showEditComp('weather')">
                <i class="icon cf__icon">
                  <my_svg
                    :icon="'dew_point'"
                    :size="'37.5'"
                    :color="'rgb(145, 145, 145)'"
                  />
                </i>
                <div class="desc">погода</div>
              </div>
              <div class="cf__add_comp cf__noselect" @click="showEditComp('iframe')">
                <i class="icon cf__icon">
                  <my_svg
                    :icon="'web_asset'"
                    :size="'37.5'"
                    :color="'rgb(145, 145, 145)'"
                  />
                </i>
                <div class="desc">iframe</div>
              </div>
              <div class="cf__add_comp cf__noselect" @click="showEditComp('log')">
                <i class="icon cf__icon">
                  <my_svg :icon="'feed'" :size="'37.5'" :color="'rgb(145, 145, 145)'" />
                </i>
                <div class="desc">лог</div>
              </div>
              <div class="cf__add_comp cf__noselect" @click="showEditComp('slider1')">
                <i class="icon cf__icon">
                  <my_svg
                    :icon="'linear_scale'"
                    :size="'37.5'"
                    :color="'rgb(145, 145, 145)'"
                  />
                </i>
                <div class="desc" style="white-space: nowrap; font-size: 90%">
                  слайдер #1
                </div>
              </div>
              <div class="cf__add_comp cf__noselect" @click="showEditComp('text')">
                <i class="icon cf__icon">
                  <my_svg
                    :icon="'article'"
                    :size="'37.5'"
                    :color="'rgb(145, 145, 145)'"
                  />
                </i>
                <div class="desc" style="white-space: nowrap; font-size: 90%">текст</div>
              </div>

              <div class="cf__add_comp cf__noselect" @click="showEditComp('therm')">
                <i class="icon cf__icon">
                  <my_svg
                    :icon="'thermostat'"
                    :size="'37.5'"
                    :color="'rgb(145, 145, 145)'"
                  />
                </i>
                <div class="desc" style="white-space: nowrap; font-size: 90%">
                  термометр
                </div>
              </div>

              <div class="cf__add_comp cf__noselect" @click="showEditComp('image')">
                <i class="icon cf__icon">
                  <my_svg :icon="'image'" :size="'37.5'" :color="'rgb(145, 145, 145)'" />
                </i>
                <div class="desc" style="white-space: nowrap; font-size: 90%">
                  картинка
                </div>
              </div>

              <div class="cf__add_comp cf__noselect" @click="showEditComp('therm_plus')">
                <i class="icon cf__icon">
                  <my_svg
                    :icon="'thermostat'"
                    :size="'37.5'"
                    :color="'rgb(145, 145, 145)'"
                  />
                </i>
                <div class="desc" style="white-space: nowrap; font-size: 90%">
                  термометр+
                </div>
              </div>

              <div class="cf__add_comp cf__noselect" @click="showEditComp('toggle_plus')">
                <i class="icon cf__icon">
                  <my_svg
                    :icon="'toggle_on'"
                    :size="'37.5'"
                    :color="'rgb(145, 145, 145)'"
                  />
                </i>
                <div class="desc" style="white-space: nowrap; font-size: 90%">
                  тумблер
                </div>
              </div>

              <div class="cf__add_comp cf__noselect" @click="showEditComp('icon')">
                <i class="icon cf__icon">
                  <my_svg
                    :icon="'satellite'"
                    :size="'37.5'"
                    :color="'rgb(145, 145, 145)'"
                  />
                </i>
                <div class="desc">иконка</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col s12" style="display: flex; justify-content: space-between">
          <h5 style="font-weight: 500">Расширенные компоненты</h5>
        </div>
        <div class="col m6 s12">
          <div class="cf__add_comps">
            <div class="cf__flex" style="flex-wrap: wrap">
              <div class="cf__add_comp cf__noselect" @click="showEditComp('linechart')">
                <i class="icon cf__icon">
                  <my_svg
                    :icon="'show_chart'"
                    :size="'37.5'"
                    :color="'rgb(145, 145, 145)'"
                  />
                </i>
                <div class="desc" style="white-space: nowrap; font-size: 90%">
                  график #1
                </div>
              </div>
              <div class="cf__add_comp cf__noselect" @click="showEditComp('barchart')">
                <i class="icon cf__icon">
                  <my_svg
                    :icon="'bar_chart'"
                    :size="'37.5'"
                    :color="'rgb(145, 145, 145)'"
                  />
                </i>
                <div class="desc" style="white-space: nowrap; font-size: 90%">
                  график #2
                </div>
              </div>
              <div class="cf__add_comp cf__noselect" @click="showEditComp('speed')">
                <i class="icon cf__icon">
                  <my_svg :icon="'speed'" :size="'37.5'" :color="'rgb(145, 145, 145)'" />
                </i>
                <div class="desc" style="white-space: nowrap">манометр</div>
              </div>
              <!-- <div class="cf__add_comp cf__noselect cf__noclick">
                <i class="icon cf__icon">reset_image</i>
                <div class="desc" style="white-space: nowrap; font-size: 90%">
                  Картинка
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-show="gref.get('var').componentShowEdit">
        <component :is="selectedComponent" :form="true" class="col s12 m6" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, shallowRef } from "@vue/runtime-core";
import { gref, selectedComponent } from "@/helpers/gref";
import availableComponents from "@/helpers/available-components";
import my_svg from "@/helpers/svg.vue";
const showEditComp = (item) => {
  // костыль, чтобы изменить позиционирование формы для компонента toggle_plus
  if (item == "toggle_plus") gref.value.get("var").alignItemFormComponent = "start";
  else gref.value.get("var").alignItemFormComponent = "center";

  gref.value.get("var").componentShowEdit = true;
  selectedComponent.value = availableComponents(item);
};
</script>
