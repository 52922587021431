<template>
  <div>
    <div ref="style"></div>
    <div
      style="
        color: #000;
        position: fixed;
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        z-index: 100;
        overflow-x: hidden;
        background-color: #fff;
      "
      v-show="gref.get('var').styleSettings"
    >
      <div class="container">
        <div class="row">
          <div class="col s12 m12" style="display: flex; justify-content: space-between">
            <h5 style="font-weight: 500">Визуализация</h5>
          </div>
          <form class="row" @submit.prevent="saveStyleSettings()">
            <div class="col s12 m5">
              <div class="row">
                <fieldset>
                  <legend>Модули</legend>
                  <div class="cf__input_field col m6 s12">
                    <input
                      type="text"
                      placeholder="#fff"
                      v-model="objStyle.titleFontColor"
                    />
                    <span class="helper-text">Цвет заголовков</span>
                  </div>
                  <div class="cf__input_field col m6 s12">
                    <input
                      type="text"
                      placeholder="#fff"
                      v-model="objStyle.titleFontSize"
                    />
                    <span class="helper-text">Размер шрифта заголовков</span>
                  </div>
                  <div class="cf__input_field col m6 s12">
                    <input
                      type="text"
                      placeholder="#fff"
                      v-model="objStyle.timerFontColor"
                    />
                    <span class="helper-text">Цвет таймера</span>
                  </div>
                  <div class="cf__input_field col m6 s12">
                    <input
                      type="text"
                      placeholder="#fff"
                      v-model="objStyle.timerFontSize"
                    />
                    <span class="helper-text">Размер шрифта таймера</span>
                  </div>
                  <div class="cf__input_field col m6 s12">
                    <input
                      type="text"
                      placeholder="#fff"
                      v-model="objStyle.styleBorder"
                    />
                    <span class="helper-text">Стиль края у блоков</span>
                  </div>
                  <div class="cf__input_field col m6 s12">
                    <input
                      type="text"
                      placeholder="#fff"
                      v-model="objStyle.styleBackgroundColor"
                    />
                    <span class="helper-text">Цвет фона у блоков</span>
                  </div>
                  <div class="cf__input_field col m6 s12">
                    <input
                      type="text"
                      placeholder="block | none"
                      v-model="objStyle.styleTitleDisplay"
                    />
                    <span class="helper-text">Скрыть/Показать все заголовки</span>
                  </div>
                  <div class="cf__input_field col m6 s12">
                    <input
                      type="text"
                      placeholder="block | none"
                      v-model="objStyle.styleTimerDisplay"
                    />
                    <span class="helper-text">Скрыть/Показать все таймеры</span>
                  </div>
                </fieldset>

                <fieldset>
                  <legend>Переключатели вкладок</legend>
                  <div class="cf__input_field col m6 s12">
                    <input
                      type="number"
                      v-model="objStyle.styleZoomTabs"
                      min="1"
                      step="0.1"
                    />
                    <span class="helper-text">Размер кнопок</span>
                  </div>
                  <div class="cf__input_field col m6 s12">
                    <input
                      type="number"
                      v-model="objStyle.styleGapTabs"
                      min="0.5"
                      step="0.1"
                    />
                    <span class="helper-text">Расстояние между кнопками</span>
                  </div>
                  <div class="cf__input_field col m6 s12">
                    <input
                      type="text"
                      v-model="objStyle.styleBgTabs"
                      placeholder="red | #000000 | rgba(0, 0, 0, 0.5)"
                    />
                    <span class="helper-text">Фон кнопок</span>
                  </div>

                  <div class="cf__input_field col m6 s12">
                    <input
                      type="text"
                      placeholder="red | #000000 | rgba(0, 0, 0, 0.5)"
                      v-model="objStyle.styleBorderColorTabs"
                    />
                    <span class="helper-text">Цвет края кнопок</span>
                  </div>

                  <div class="cf__input_field col m6 s12">
                    <input
                      type="text"
                      placeholder="red | #000000 | rgba(0, 0, 0, 0.5)"
                      v-model="objStyle.styleTextColorTabs"
                    />
                    <span class="helper-text">Цвет текста кнопок</span>
                  </div>

                  <div class="cf__input_field col m6 s12">
                    <input
                      type="text"
                      placeholder="red | #000000 | rgba(0, 0, 0, 0.5)"
                      v-model="objStyle.styleHoverTextColorTabs"
                    />
                    <span class="helper-text">Цвет текста при наведении</span>
                  </div>
                </fieldset>

                <!-- off -->
                <div v-show="false" class="cf__input_field col m6 s12">
                  <input
                    type="text"
                    placeholder="red | #000000 | rgba(0, 0, 0, 0.5)"
                    v-model="objStyle.styleMainBackgroundColor"
                  />
                  <span class="helper-text">Цвет фона</span>
                </div>
                <!-- off -->

                <div>
                  <div
                    class="cf__input_field col s12"
                    style="
                      display: flex;
                      -moz-column-gap: 10px;
                      column-gap: 10px;
                      row-gap: 10px;
                      margin-top: 1rem;
                    "
                  >
                    <button class="btn waves-effect waves-light" type="submit">
                      Сохранить
                    </button>
                    <button
                      class="btn waves-effect waves-light grey lighten-5 cf__text_41"
                      type="button"
                      @click="resetStyle()"
                    >
                      Сбросить
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted } from "@vue/runtime-core";
import { gref } from "@/helpers/gref";

const style = ref(null);

const defaultObjStyle = () => ({
  titleFontColor: "#fff",
  titleFontSize: "16px",
  timerFontColor: "#8b8b8b",
  timerFontSize: "90%",
  styleBorder: "none",
  styleBackgroundColor: "rgba(59, 59, 59, 0.9)",
  styleTitleDisplay: "block",
  styleTimerDisplay: "block",
  styleZoomTabs: 1,
  styleGapTabs: 0.5,
  styleBgTabs: "transparent",
  styleBorderColorTabs: "rgba(255, 255, 255, .2)",
  styleTextColorTabs: "rgba(255,255,255, .2)",
  styleHoverTextColorTabs: "#fff",
  styleMainBackgroundColor: "rgba(0, 0, 0, 1)",
});

const objStyle = ref({});

function resetStyle() {
  objStyle.value = defaultObjStyle();
}

function setStyle() {
  style.value.innerHTML = `<style>
  .cf__component .title {
    color: ${objStyle.value.titleFontColor};
    font-size: ${objStyle.value.titleFontSize};
    display: ${objStyle.value.styleTitleDisplay};
  }
  .vue-grid-item:not(.vue-grid-placeholder) {
    background-color: ${objStyle.value.styleBackgroundColor};
    border: ${objStyle.value.styleBorder};
  }
  .cf__component .time {
    font-size: ${objStyle.value.timerFontSize};
    color: ${objStyle.value.timerFontColor};
    display: ${objStyle.value.styleTimerDisplay};
  }
  .cf__num_layers {
    zoom: ${objStyle.value.styleZoomTabs};
    background-color: ${objStyle.value.styleBgTabs};
    border: 1px solid ${objStyle.value.styleBorderColorTabs} !important;
    color: ${objStyle.value.styleTextColorTabs} !important;
  }
  .cf__num_layers:hover {
    color: ${objStyle.value.styleHoverTextColorTabs} !important;
  }
  .cf__layers_button {
    gap: ${objStyle.value.styleGapTabs}rem;
  }
  </style>`;
}

function saveStyle() {
  localStorage.setItem("style", JSON.stringify(objStyle.value));
}

function loadStyle() {
  if (localStorage.getItem("style"))
    objStyle.value = JSON.parse(localStorage.getItem("style"));
}
function saveStyleSettings() {
  setStyle();
  saveStyle();
  // gref.value.get("var").styleSettings = !gref.value.get("var").styleSettings;
  // 08-10-2023
  window.document.location.reload();
}

onMounted(() => {
  objStyle.value = defaultObjStyle();
  loadStyle();
  setStyle();
});
</script>
